import type { ButtonHTMLAttributes } from "react";

export default function Button(props: ButtonHTMLAttributes<HTMLButtonElement>) {
  return (
    <button
      type="submit"
      {...props}
      className={`rounded bg-teal-500 p-2 text-teal-50 disabled:bg-gray-400 dark:bg-teal-500 dark:text-slate-800 dark:disabled:bg-gray-500 ${props.className}`}
    >
      {props.children}
    </button>
  );
}
