import { useRef, forwardRef } from "react";
import type { InputHTMLAttributes } from "react";

type InputProps = InputHTMLAttributes<HTMLInputElement> & {
  ref?: React.Ref<HTMLInputElement>;
};

const Input = forwardRef(function Input(
  props: InputProps,
  ref: React.Ref<HTMLInputElement>
) {
  return (
    <input
      type="text"
      ref={ref}
      {...props}
      className={`${props.className} w-full rounded border border-slate-800 bg-white p-2 text-black dark:bg-slate-500 dark:text-slate-50`}
    >
      {props.children}
    </input>
  );
});

export default Input;
